import styled from 'styled-components';
import variables from '../variables';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 66px;
  z-index: 999;
  background: linear-gradient(
    180deg,
    #ffffff 73.54%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 18px;

  a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  img {
    display: block;
    width: 40px;
    pointer-events: none;
  }
`;

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px;

  a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  img {
    display: block;
    width: 40px;
    pointer-events: none;
  }
`;

export const Breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p,
  a,
  img {
    display: inline-block;
    font-weight: 400;
    font-size: ${variables.typeSizeBase};
    line-height: 20px;
    letter-spacing: -0.01em;
    margin-left: 8px;
    color: #000;
  }
  a {
    cursor: pointer;
  }
  span.ball,
  span.chakra-avatar {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #999;
    margin-left: 8px;
    vertical-align: bottom;
  }
`;
