import styled from 'styled-components';
import variables from '../variables';
import { ScaleFade } from '@chakra-ui/react';

export const ScaleFadeStyle = styled(ScaleFade)`
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1800;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .mask {
    bottom: 0;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .panel {
    max-height: max(50vh, 350px);
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    box-shadow: ${variables.boxShadow};
    left: 50%;
    transform: translateX(-50%);
    top: 20%;
    max-width: 640px;
    width: 100%;
    z-index: 1000;
    backdrop-filter: blur(8px);
    position: absolute;
    padding-top: 48px;

    @media only screen and (max-width: 1040px) {
      top: 0;
      left: 0;
      right: 0;
      margin: 88px auto;
      max-width: calc(100% - 16px);
      transform: none;
    }
  }

  .input-search {
    padding: 12px;
  }

  .input-search input {
    color: #fff !important;
    border: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .bar {
    height: 48px;
    margin-bottom: 8px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;

    .icon {
      flex: 0;
      align-items: center;
      display: inline-flex;
      height: 32px;
      justify-content: center;
      width: 16px;
    }

    > div {
      flex: 1;

      input {
        border: 0 !important;
        font-size: ${variables.typeSizeBase};
        line-height: 21px;

        &::placeholder {
          color: ${variables.paletteGrayBase2};
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .results {
    height: auto;
    padding-block: 16px;
    max-height: calc(max(50vh, 350px) - 48px);
    overflow-y: scroll;
    scrollbar-width: none;

    .section-title {
      color: #666;
      margin-left: 16px;
      margin-bottom: 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .search-results {
      display: flex;
      flex-direction: column;
      margin-inline: 8px;

      .search-item {
        display: flex;
        align-items: flex-start;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;

        &:hover,
        &:focus {
          outline: none;
          background-color: rgba(255, 255, 255, 0.08);
        }

        &:focus {
          .content {
            & > span:last-child {
              visibility: visible;
            }
          }
        }
      }
    }

    .page-results {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
      margin-inline: 16px;
      margin-bottom: 24px;

      .page {
        display: flex;
        align-items: flex-start;
        padding: 8px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.08);
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;

        &:hover,
        &:focus {
          outline: none;
          background-color: rgba(255, 255, 255, 0.2);
        }

        &:focus {
          .content {
            & > span:last-child {
              visibility: visible;
            }
          }
        }
      }

      @media only screen and (max-width: 1040px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .user-results {
      padding: 0 16px;
      margin-bottom: 24px;
      display: grid;
      grid-template-columns: repeat(13, 1fr);
      gap: 16px;

      .avatar {
        font-size: 0;
        position: relative;
        width: 32px;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
      @media only screen and (max-width: 1040px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .project-results {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
      margin-inline: 16px;
      margin-bottom: 24px;

      .project {
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        height: 90px;
        padding: 12px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.08);
        transition: background-color 0.2s ease-in-out;

        &:hover,
        &:focus {
          outline: none;
          background-color: rgba(255, 255, 255, 0.2);
        }

        &.pinned {
          .meta {
            span,
            i {
              line-height: 16px;
              vertical-align: middle;
            }

            i {
              background-image: url('./pin-active.svg');
              background-repeat: no-repeat;
              background-position: center center;
              display: inline-block;
              height: 16px;
              margin-left: 6px;
              outline: none;
              width: 16px;
            }
          }
        }

        &.group {
          .content {
            color: #fff !important;
          }
        }

        &.user {
          .avatar {
            font-size: 0;
            margin-right: 8px;
            vertical-align: middle;

            > div {
              height: 16px;
              width: 16px;
            }
          }
        }

        &:focus {
          .content {
            & > span:last-child {
              display: flex;
              visibility: visible;
            }
          }
        }

        .meta {
          color: ${variables.paletteGrayTextXLight};
          display: inline-block;
          float: right;
          font-size: ${variables.typeSizeBase};
          line-height: 16px;
          vertical-align: middle;
        }
      }

      @media only screen and (max-width: 580px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .results::-webkit-scrollbar {
    display: none;
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;

    & > span:first-child {
      text-align: left;
    }

    & > span:last-child {
      flex-shrink: 0;
      visibility: hidden;
      display: flex;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.08);
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      color: #999999;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .search-results {
    .content {
      & > span:last-child {
        width: auto;
        padding-inline: 4px;
      }
    }
  }

  .empty {
    color: ${variables.paletteGrayBase};
    font-size: ${variables.typeSizeBase};
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
`;
