export function readCookie(name) {
  if (typeof document === 'undefined') {
    return false;
  }

  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return false;
}

export function eraseCookie(name) {
  document.cookie = name + '=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie =
    name +
    '=;path=/;domain=.onroadmap.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
