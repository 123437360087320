import styled from 'styled-components';
import variables from '../variables';
import { Box } from '@chakra-ui/react';

export const ToastStyle = styled(Box)`
  background-color: #000;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 32px 8px 12px;
  position: relative;

  .icon {
    border-radius: 100px;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 8px;
    top: 10px;
    transition: background-color 0.2s;

    &:hover {
      background-color: ${variables.paletteGrayLight};

      svg {
        path {
          stroke: #000;
        }
      }
    }

    svg {
      path {
        stroke: #fff;
        transition: stroke 0.2s;
      }
    }
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
  }
`;
