import styled from 'styled-components';
import variables from '../variables';
import { Tooltip } from '@chakra-ui/react';

export const TooltipStyle = styled(Tooltip)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 6px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24) !important;
  font-size: ${variables.typesizeBase};
  line-height: 20px;
  padding: 6px 8px;
  user-select: none;
  z-index: 2147483647;

  &.info {
    padding: 16px !important;
  }

  .title {
    color: #fff;
    font-size: ${variables.typesizeBase};
  }

  .text {
    color: ${variables.paletteGrayTextXXLight};
    font-size: ${variables.typesizeBase};
  }

  .subtext {
    color: ${variables.paletteGrayTextXXLight};
    font-size: ${variables.typesizeBase};
  }

  .timelines {
    > div {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .timeline {
      margin-bottom: 12px;
      position: relative;

      &.timeoff {
        color: ${variables.paletteRedBase};
      }

      .title,
      .project,
      .taskgroup {
        font-size: ${variables.typesizeBase};
        line-height: 20px;
      }

      .taskgroup {
        color: #fff;
        display: inline-block;
      }
    }
  }
`;
