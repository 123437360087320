import { createGlobalStyle } from 'styled-components';
import variables from '../../variables';
import CheckboxIcon from '../../../icons/check-radio.svg';

const NotificationStyle = `
  .notification {
    bottom: 16px;
    cursor: default;
    left: -100%;
    margin: 0;
    opacity: 0;
    position: fixed;
    transform: translatez(0);
    width: auto;
    z-index: 2147483647;
  
    &:hover {
      .notification-bar-action-icon {
        background: rgba(211, 197, 73, 0.32);
      }
    }
  
    .notification-bar-action {
      font-size: 0;
      position: absolute;
      right: 8px;
      top: 11px;
  
      .notification-bar-action-icon {
        padding: 3px;
      }
    }
  
    &.error {
      background-color: rgba(0, 0, 0, 0.9);
      border-radius: 6px;
      left: -100%;
      opacity: 0.85;
      padding: 8px 16px;
      right: auto;
      transition: left 0.5s cubic-bezier(0.89, 0.01, 0.5, 1.1);
  
      &:hover {
        .notification-bar-action-icon {
          background: none;
        }
      }
  
      &.notification-bar-active {
        left: 16px;
      }
  
      .notification-bar-message {
        color: #fff;
        display: inline-block;
        font-size: ${variables.typeSizeBase};
        line-height: 18px;
        padding-right: 40px;
      }
  
      .notification-bar-link {
        cursor: pointer;
        font-family: ${variables.fontMedium};
        font-weight: 500;
        text-decoration: underline;
      }
    }
  
    &.greeting {
      background: #fff;
      border-radius: 8px;
      bottom: 62px;
      box-shadow: 0 1px 6px 0 rgba(98, 103, 109, 0.3);
      left: auto;
      opacity: 0;
      padding: 24px;
      pointer-events: none;
      right: 18px;
      transform: scale(0.8);
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
      width: 260px;
  
      a {
        display: inline-block;
        line-height: 30px;
        margin-top: 16px;
  
        &:first-of-type {
          background-color: ${variables.paletteGreenBase};
          border-radius: 8px;
          color: #fff;
          font-family: ${variables.fontMedium};
          font-size: ${variables.typeSizeBase};
          font-weight: 500;
          padding: 0 8px;
          text-decoration: none;
        }
  
        &:last-of-type {
          color: ${variables.paletteGrayBase};
          font-size: ${variables.typeSizeBase};
          margin-left: 16px;
          text-decoration: underline;
        }
      }
  
      &:hover {
        .notification-bar-action-icon {
          background: none;
        }
      }
  
      &.notification-bar-active {
        opacity: 1;
        pointer-events: all;
        transform: scale(1);
      }
  
      .notification-bar-action {
        right: 16px;
        top: 16px;
  
        .notification-bar-action-icon {
          padding: 0;
        }
      }
  
      .notification-bar-title {
        color: #000;
        font-family: ${variables.fontMedium};
        font-size: ${variables.typeSizeBase};
        line-height: 24px;
        margin-bottom: 8px;
      }
  
      .notification-bar-message {
        color: ${variables.paletteGrayTextDark};
        font-size: ${variables.typeSizeBase};
        line-height: 21px;
      }
    }
  }
  
  .notification-bar-message {
    color: #3e3e3a;
    font-size: ${variables.typeSizeBase};
  }
  
  .notification-bar-action {
    margin-left: 24px;
  
    .notification-bar-action-icon {
      border-radius: 50%;
      box-sizing: content-box;
      cursor: pointer;
      height: 10px;
      padding: 8px;
      width: 10px;
  
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
  
  .notification-bar-title {
    display: block;
    font-size: ${variables.typeSizeBase};
    line-height: 22px;
    margin-bottom: 5px;
  }
`;

const DropdownStyle = `
  .dropdown {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
    height: auto;
    outline: none;
    padding: 8px 4px;
    text-align: left;
    transform: translateZ(0);
    width: 200px;
    z-index: 101;

    &.medium {
      width: 340px;
    }

    > ul, 
    .dropdown-list {
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        color: #000;
        cursor: pointer;
        line-height: 0;
        margin-bottom: 8px;
        padding: 2px 8px;
        position: relative;
        user-select: none;

        &:last-of-type {
          margin-bottom: 0;
        }

        span {
          font-size: ${variables.typeSizeBase};
          line-height: 20px;
        }

        &:hover,
        &.dropdown-item-active {
          background-color: ${variables.paletteGrayLight};
          border-radius: 6px;
        }

        &.dropdown-item-link {
          padding: 0;

          a {
            display: block;
            text-decoration: none;
          }
        }

        &.dropdown-item-secondary {
          color: #9aa1aa;
        }
  
        &.dropdown-item-warning {
          color: #eb4962 !important;

          &:hover {
            background-color: rgba(${variables.paletteValRedBase}, 0.06) !important;
          }
        }

        &.dropdown-item-share .react-toggle,
        &.dropdown-item-toggle .react-toggle {
          pointer-events: none;
          position: absolute;
          right: 8px;
          top: 3px;
        }

        &.dropdown-separator {
          cursor: default;
          height: 1px;
          margin: 6px 0;
          padding: 0;
          pointer-events: none;
        
          &:after {
            border-bottom: 1px solid ${variables.paletteGrayBorder};
            bottom: 0;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;
          }
        }
      }
    }
  }
`;

const ReactMentionsStyle = `
  .comment-box__control {
    textarea {
      font-size: ${variables.typeSizeBase};
      line-height: 20px;

      &::placeholder {
        color: ${variables.paletteGrayBase2};
      }
    }
  }

  .comment-box__highlighter {
    strong {
      background-color: ${variables.paletteGrayLight};
      border-radius: 6px;
      font-weight: normal !important;
      line-height: 20px;
    }
  }

  .comment-box__suggestions {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
    outline: 0;
    padding: 8px 4px;
    width: 200px;

    .comment-box__suggestions__item {
      border-radius: 6px;
      margin-bottom: 8px;
      padding: 2px 8px;
      transition: background-color 0.2s;

      &:hover,
      &.comment-box__suggestions__item--focused {
        background-color: ${variables.paletteGrayLight};
      }

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

const ReactToggleStyle = `
  .react-toggle {
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    user-select: none;
    vertical-align: middle;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-track {
    border-radius: 8px;
    border: 1px solid #000000;
    background-color: #fff;
    height: 16px;
    padding: 0;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 24px;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #000000;
    box-sizing: border-box;
    transition: all 0.25s ease;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: #000;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #fff;
    left: 11px;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-check {
    display: none;
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    opacity: 0;
  }
`;

const ChakraToastStyle = `
  #chakra-toast-manager-bottom-right {
    > li {
      .chakra-toast__inner {
        margin: 8px 8px 0 8px !important;
      }

      &:last-of-type {
        .chakra-toast__inner {
          margin-bottom: 8px !important;
        }
      }
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  a {
    color: ${variables.paletteBrandBase};
    text-decoration: none !important;
  }
  
  h1 {
    font-size: ${variables.typeSizeBase};
    font-weight: 400;
  }
  
  h2 {
    font-size: ${variables.typeSizeBase};
    font-weight: 400;
  }
  
  p {
    font-size: ${variables.typeSizeBase};
  }
  
  label {
    color: ${variables.paletteGrayBase2Dark};
    display: block;
    font-size: ${variables.typeSizeBase};
    line-height: 20px;
    margin: 0 0 8px 0;
    text-align: left;
    user-select: none;
  }

  input,
  textarea {
    &::placeholder {
      color: ${variables.paletteGrayBase2};
    }
  }

  i.info {
    background-color: ${variables.paletteGrayBorder};
    border-radius: 10px;
    color: ${variables.paletteGrayText};
    display: inline-block;
    font-family: ${variables.fontBold};
    font-size: ${variables.typeSizeBase};
    font-style: normal;
    height: 16px;
    line-height: 16px;
    margin-left: 8px;
    margin-top: -1px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    user-select: none;
    vertical-align: middle;
    width: 16px;
  
    &:global(.info-round) {
      border-radius: 50%;
    }
  
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  .checkbox {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    height: 18px;
    transition: border-color .3s;
    width: 18px;

    &.checkboxChecked {
      background-color: ${variables.paletteGrayTextDark};
      background-image: url(${CheckboxIcon});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 8px;
      border: 0;

      .checkboxMask {
        background-color: rgba(61,64,69,0.56);
        background-image: url(${CheckboxIcon});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 8px 12px;
      }
    }

    .checkboxMask {
      border-radius: 18px;
      left: 0;
      position: absolute;
      top: 0;
      vertical-align: top;
    }
  }

  .griditem-daterange {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
    color: #fff;
    font-size: ${variables.typeSizeBase};
    line-height: 20px;
    left: 0;
    padding: 6px 8px;
    position: fixed;
    top: 0;
    z-index: 3;
  }

  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }

  ${NotificationStyle}
  ${DropdownStyle}
  ${ReactMentionsStyle}
  ${ReactToggleStyle}
  ${ChakraToastStyle}
`;

export default GlobalStyle;
