export const env = process.env.ROADMAP_ENV || 'local';
export const port = process.env.PORT || 3000;
export const s3ImagePath = 'https://d33wanxuedozem.cloudfront.net/images';
export const slackOAuthURL = 'https://slack.com/oauth/authorize';
export const apiEndpoint =
  process.env.REACT_APP_SERVERENDPOINT || 'http://localhost:5000';
export const pusherKey =
  process.env.REACT_APP_PUSHER_KEY || '140946a33b35cea39628';
export const fullStoryOrg = process.env.REACT_APP_FULLSTORY_ORG || 'D82MC';
export const slackClientID =
  process.env.REACT_APP_SLACK_CLIENT_ID || '87050298131.95928395911';
