import styled from 'styled-components';
import variables from '../../../components/variables';

export const Root = styled.div`
  text-align: center;

  .header {
    position: relative;
    width: 100%;
    z-index: 1;

    .left {
      float: left;
      padding: 18px 0 0 24px;
    }

    .right {
      float: right;
      padding: 16px;
    }
  }

  .content {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .container {
    margin: 0 auto;
    max-width: 304px;
    width: 100%;

    .actions {
      text-align: left;

      .button {
        display: inline-block;
      }
    }
  }

  .section {
    margin-bottom: 16px;

    &.inputs {
      margin-bottom: 24px;
    }

    &.separator {
      color: ${variables.paletteGrayTextDark};
      font-size: ${variables.typesizeBase};
    }

    > div {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .password {
    position: relative;

    input {
      padding-right: 72px;
    }

    a {
      color: ${variables.paletteGrayText};
      font-size: ${variables.typesizeBase};
      line-height: 20px;
      position: absolute;
      right: 8px;
      text-decoration: none;
      top: 10px;
      z-index: 2;
    }
  }

  @media only screen and (min-width: 1024px) {
    & {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
`;
