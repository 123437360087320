import React from 'react';
import { Container } from './styles';
import logo from '../../icons/op-logo.svg';
import Link from '../Link';

const Header = (props) => {
  return (
    <Container>
      <Link to="/office">
        <img src={logo} alt="logo" />
      </Link>
    </Container>
  );
};

export default Header;
