import { apiEndpoint } from '../../config';
import { readCookie, eraseCookie } from '../cookies';

export function logout(skipRedirect) {
  const winloc = window?.location;
  eraseCookie('at');
  eraseCookie('rt');
  eraseCookie('u');

  window?.Intercom?.('shutdown');
  window?.FS?.clearUserCookie();
  if (!winloc) {
    return;
  }

  if (skipRedirect) {
    window.location.href = '/';
    return;
  }

  window.location.href = `/login?next=${encodeURIComponent(winloc.pathname)}`;
}

export function getAuthHeaders() {
  return new Headers({
    Authorization: `bearer ${readCookie('at')}`,
    'Timezone-Offset': -(new Date().getTimezoneOffset() * 60),
  });
}

export function api(url, method, body, okCb, errCb, isAuthless = false) {
  const params = { method, mode: 'cors' };
  body && (params.body = JSON.stringify(body));
  !isAuthless && (params.headers = getAuthHeaders());

  const handler = fetch(`${apiEndpoint}/${url}`, params)
    .then((resp) => resp.json())
    .then((json = {}) => {
      const team = readCookie('t');
      if (typeof json.error !== 'undefined') {
        if (json.error.code === 401) {
          handleClientError(json.error);
          return;
        } else if (json.error.code === 404 && !team) {
          logout(true);
        }

        errCb && errCb(json.error, json);
        return;
      }

      okCb && okCb(json.data || {}, json);
    })
    .catch((ex) => {
      console.log(ex);
    });

  return handler;
}

export function handleClientError(response) {
  return new Promise((resolve, reject) => {
    if (response.code !== 401) {
      reject(new Error(response.message));
      return;
    }

    const token = readCookie('rt');
    const user = readCookie('u');
    if (!token || !user) {
      if (
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/register'
      ) {
        logout();
      }

      reject();
      return;
    }

    try {
      fetch(`${apiEndpoint}/v1/auth/refresh`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ token, user }),
      })
        .then((resp) => resp.json())
        .then((json) => {
          const exp = new Date(
            Date.now() + 1000 * 60 * 60 * 24 * 30
          ).toGMTString();
          const path = window.location.pathname || '';
          if (!json || !json.data || json.error) {
            if (path !== '/login' && path !== '/register') {
              logout();
            }

            reject();
            return;
          }

          if (!json.data.accessToken || !json.data.refreshToken) {
            if (path !== '/login' && path !== '/register') {
              logout();
            }

            reject();
            return;
          }

          document.cookie = `at=${json.data.accessToken};path=/;expires=${exp}`;
          document.cookie = `rt=${json.data.refreshToken};path=/;expires=${exp}`;
          document.cookie = `u=${user};path=/;expires=${exp}`;
          setTimeout(() => resolve(json), 1000);
          setTimeout(() => window.location.reload(), 1500);
        })
        .catch((ex) => {
          logout();
          reject(new Error(`Failed to refresh API access token. ${ex}`));
        });
    } catch (ex) {
      logout();
      reject(new Error(ex));
    }
  });
}
