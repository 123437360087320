import React from 'react';
import { Link, Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

const NoMatch = () => {
  const history = useHistory();
  const auth = localStorage['appState']
    ? JSON.parse(localStorage['appState']).user
    : { isAuthenticated: false };

  function goBack(e) {
    e.preventDefault();
    if (auth.isAuthenticated) {
      history.push('/home');
    } else {
      history.push('/');
    }
  }

  return (
    <div className="notFoundContainer">
      <div>
        <div className="mainbox"></div>
        <div className="infoContainer">
          <h1>Looks like you got lost.</h1>
          <p>
            This link doesn’t exist. Maybe the link was a mirage. Or did you
            mistype it? Check again, there’s nothing here.
          </p>
          <Link textAlign="center" onClick={(e) => goBack(e)}>
            {auth.isAuthenticated ? 'Go to dashboard' : 'Go back to website'}
          </Link>
        </div>
        <Flex align="center" justify="center"></Flex>
      </div>
    </div>
  );
};

export default NoMatch;
