import React from 'react';
import { theme } from '@chakra-ui/react';

const customIcons = {
  searchRound: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569ZM6.99178 3.54032C7.60184 3.18631 8.29466 2.9999 9 3C9.61503 3.00015 10.2217 3.14212 10.773 3.41487C11.3242 3.68763 11.8051 4.08382 12.1783 4.57266C12.5516 5.0615 12.807 5.62982 12.9249 6.23345C13.0428 6.83708 13.0198 7.45975 12.8579 8.05308C12.696 8.64641 12.3994 9.19441 11.9913 9.65449C11.5832 10.1146 11.0744 10.4743 10.5046 10.7058C9.93482 10.9373 9.31931 11.0343 8.70594 10.9892C8.09257 10.9442 7.49785 10.7583 6.968 10.446L4.707 12.707C4.51936 12.8945 4.26492 12.9998 3.99965 12.9997C3.8683 12.9997 3.73824 12.9737 3.61691 12.9234C3.49558 12.8731 3.38534 12.7994 3.2925 12.7065C3.19966 12.6136 3.12602 12.5033 3.0758 12.3819C3.02558 12.2606 2.99975 12.1305 2.9998 11.9991C2.99989 11.7339 3.10536 11.4795 3.293 11.292L5.554 9.032C5.19569 8.42445 5.0044 7.73296 4.99952 7.02764C4.99465 6.32232 5.17636 5.62826 5.52624 5.01582C5.87612 4.40338 6.38172 3.89434 6.99178 3.54032ZM7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7C7 7.53043 7.21071 8.03914 7.58579 8.41421C7.96086 8.78929 8.46957 9 9 9C9.53043 9 10.0391 8.78929 10.4142 8.41421C10.7893 8.03914 11 7.53043 11 7C11 6.46957 10.7893 5.96086 10.4142 5.58579C10.0391 5.21071 9.53043 5 9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579Z"
      />
    ),
    // If the icon's viewBox is `0 0 24 24`, you can ignore `viewBox`
    viewBox: '0 0 16 16',
  },
};

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    blueDark: {
      50: '#e6f2ff',
      100: '#c1d5f1',
      200: '#9bb9e3',
      300: '#759dd7',
      400: '#4f82ca',
      500: '#1c3a64',
      600: '#29518a',
      700: '#1c3a64',
      800: '#0e233e',
      900: '#010c1a',
    },
    grayDark: {
      50: '#1c3a64',
      100: '#1c3a64',
      200: '#1c3a64',
      300: '#1c3a64',
      400: '#1c3a64',
      500: '#E2E8F0',
      600: '#1c3a64',
      700: '#1c3a64',
      800: '#1c3a64',
      900: '#1c3a64',
    },
  },
  icons: {
    ...theme.icons,
    ...customIcons,
  },
};
export default customTheme;
