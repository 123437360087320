import React from 'react';
import { render } from 'react-dom';
import { withRouter, BrowserRouter } from 'react-router-dom';
import './styles/app.scss';
import App from './App';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import theme from './theme';
import MainStore from './Stores/MainStore';
import Seo from './components/Seo/Seo';
// import reportWebVitals from './reportWebVitals';

const AppContainer = withRouter((props) => <App {...props} />);

render(
  <ChakraProvider theme={theme}>
    <CSSReset />
    <MainStore>
      <Seo title={''} />
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </MainStore>
  </ChakraProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
