import React, { createContext, useReducer, useEffect } from 'react';

export const MainContext = createContext({});

export const initialState = {
  user: null,
  team: null,
  menuSlided: false,
  menuHover: false,
  navigation: {
    isActive: false,
  },
  isShowShortcuts: false,
  isShowHelpDropdown: false,
  onboardingModal: false,
  billingModal: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'user':
      return { ...state, user: action.payload };
    case 'team':
      return { ...state, team: action.payload };
    case 'menuSlided':
      return { ...state, menuSlided: action.payload };
    case 'menuHover':
      return { ...state, menuHover: action.payload };
    case 'navigation':
      return { ...state, navigation: action.payload };
    case 'isShowShortcuts':
      return { ...state, isShowShortcuts: action.payload };
    case 'isShowHelpDropdown':
      return { ...state, isShowHelpDropdown: action.payload };
    case 'onboardingModal':
      return { ...state, onboardingModal: action.payload };
    case 'billingModal':
      return { ...state, billingModal: action.payload };
    default:
      throw new Error('Action type must be defined');
  }
}

const MainStore = ({ children, user }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (user) {
      dispatch({ type: 'user', payload: user });
    }
  }, [user, dispatch]);

  return (
    <MainContext.Provider value={{ state, dispatch }}>
      {children}
    </MainContext.Provider>
  );
};

export default React.memo(MainStore);
