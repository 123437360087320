import styled from 'styled-components';
import variables from '../variables';

export const RootBtn = styled.button`
  align-items: center;
  background-image: none;
  border: 0;
  border: 1px solid #000000;
  border-radius: 20px;
  color: #000;
  cursor: pointer;
  font-family: ${variables.fontMedium};
  font-size: ${variables.typeSizeBase};
  display: inline-flex;
  justify-content: center;
  height: 32px;
  line-height: 32px;
  margin-bottom: 0;
  padding: 0 8px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.2s, color 0.2s;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:focus {
    outline: 0;
  }

  &:hover,
  &.active {
    background-color: ${variables.paletteBlackBase};
    color: #fff;
  }

  &.transparent {
    background-color: transparent;
    box-shadow: none;
    border: 0;
    color: ${variables.paletteDarkBase};
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;

    &:hover {
      background-color: transparent !important;
    }
  }

  &.warning {
    border-color: ${variables.paletteRedBase};
    color: ${variables.paletteRedBase};
    float: right;

    &:hover {
      background-color: ${variables.paletteRedBase};
      color: #fff;

      &.transparent {
        color: ${variables.paletteRedBase};
      }
    }
  }

  &.paddingless {
    padding: 0 !important;
  }

  &.short {
    padding: 0 16px;
  }

  &.full {
    width: 100%;
  }

  &.small {
    font-size: ${variables.typeSizeBase};
    line-height: 20px;
    padding: 3px 12px;
  }

  &.large {
    font-size: ${variables.typeSizeBase};
    line-height: 26px;
  }

  &.disabled {
    border-color: ${variables.paletteGrayBase2};
    color: ${variables.paletteGrayBase2};
    cursor: default;
    pointer-events: none;
  }

  &.readonly {
    cursor: default;
  }

  &.secondary {
    border-color: ${variables.paletteGrayText};
    color: ${variables.paletteGrayText};

    &:hover {
      background-color: ${variables.paletteGrayText};
      color: #fff;

      &.transparent {
        color: ${variables.paletteGrayText};
      }
    }
  }

  svg {
    display: inline-block;
    height: 16px;
    margin-right: 8px;
    vertical-align: middle;
    width: 16px;
  }
`;
