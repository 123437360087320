import React from 'react';
import { parsePath } from 'history';
import { useHistory } from 'react-router-dom';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const Link = (props) => {
  const history = useHistory();

  const handleClick = (event) => {
    let allowTransition = true;
    let clickResult;

    if (props && props.onClick) {
      clickResult = props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (clickResult === false || event.defaultPrevented === true) {
      allowTransition = false;
    }

    event.preventDefault();

    if (allowTransition) {
      const link = event.currentTarget;
      if (props && props.to) {
        history.push({
          ...parsePath(props.to),
          state: (props && props.state) || null,
        });
      } else {
        history.push({
          pathname: link.pathname,
          search: link.search,
          state: (props && props.state) || null,
        });
      }
    }
  };

  return <a href={props.to} {...props} onClick={handleClick} />;
};

export default Link;
