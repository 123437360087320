import styled from 'styled-components';
import variables from '../variables';

export const RootStyle = styled.div`
  border-radius: 50%;
  background-color: transparent;
  display: inline-block;
  font-size: 0;
  overflow: hidden;
  user-select: none;
  width: ${(props) =>
    props.styles && props.styles.width ? props.styles.width : '30px'};
  height: ${(props) =>
    props.styles && props.styles.height ? props.styles.height : '30px'};

  .image {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  .fallback {
    background-color: #000;
    color: #fff;
    font-size: ${variables.typeSizeBase};
    height: 100%;
    line-height: 30px;
    text-align: center;
    width: 100%;
  }
`;
