import React, { Component } from 'react';
import { RootStyle } from './style';

class Avatar extends Component {
  constructor(props) {
    super(props);

    this.state = { isLoaded: false };
    this._image = null;
  }

  componentDidMount() {
    if (this.props.imagePath) {
      this._image = new Image();
      this._image.src = this.props.imagePath;
      this._image.onload = this.handleLoad();
    }
  }

  shouldComponentUpdate(nprops) {
    return (
      !this.state.isLoaded ||
      this.props.imagePath !== nprops.imagePath ||
      this.props.title !== nprops.title
    );
  }

  componentWillUnmount() {
    if (this._image) {
      this._image.onload = null;
      this._image = null;
    }
  }

  handleLoad = () => {
    this.setState({ isLoaded: true }, () => {
      if (this._image) {
        this._image.onload = null;
        this._image = null;
      }
    });
  };

  handleClick = (e) => {
    this.props.onClick && this.props.onClick(e);
  };

  render() {
    const props = this.props;
    const { imagePath, title, styles } = props;
    const state = this.state;
    const aStyle = {};
    const isLoaded = state.isLoaded;

    imagePath && (aStyle.backgroundImage = `url(${imagePath})`);

    return (
      <RootStyle aria-roledescription="img" style={styles}>
        {imagePath ? (
          isLoaded && <div className="image" style={aStyle} title={title} />
        ) : (
          <div className="fallback">{title?.[0]}</div>
        )}
      </RootStyle>
    );
  }
}

export default Avatar;
