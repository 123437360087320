import React, { Component } from 'react';
import formToObj from 'form-to-obj';
import classNames from 'classnames';
import { RootBtn } from './style';
import { toast } from '../Toast';
import { getAuthHeaders, handleClientError } from '../../core/auth';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonState: '',
      isSubmitting: false,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = () => {
    const { method = 'GET' } = this.props;
    const _this = this;
    const props = this.props;
    const form = document.querySelector(`#${props.form}`);
    const json = JSON.stringify(formToObj(form));
    const reqBody = { method, body: json };

    if (!form) {
      this.setState({ buttonState: props.value, isSubmitting: false });
      toast('Form not found');
      return;
    }

    if (props.onBeforeSubmit) {
      const err = props.onBeforeSubmit(json);
      if (err) {
        this.setState({ buttonState: this.props.value, isSubmitting: false });
        toast(err);
        return;
      }
    }

    if (props.authorize) {
      reqBody.headers = getAuthHeaders();
      reqBody.mode = 'cors';
    }

    if (!this.state.isSubmitting) {
      this.setState({ isSubmitting: true });

      fetch(props.action, reqBody)
        .then((response) => response.json())
        .then((response) => {
          if (typeof response.error !== 'undefined') {
            handleClientError(response.error)
              .then(() => {
                _this.handleSubmit();
              })
              .catch(() => {
                _this.setState({
                  buttonState: props.value,
                  isSubmitting: false,
                });
                props.error(response.error);
              });

            _this.setState({ buttonState: props.value, isSubmitting: false });
            return;
          }

          _this.success(response);
        })
        .catch((ex) => {
          _this.setState({ buttonState: props.value, isSubmitting: false });
          props.error && props.error(ex);
          throw new Error(`${props.action} API: ${ex}`);
        });
    }
  };

  success = (json) => {
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ buttonState: '' });
      }
    }, 1000);

    this.setState({ buttonState: 'success', isSubmitting: false });
    this.props.success(json);
  };

  handleClick = (e) => {
    const { type = 'button' } = this.props;
    if (this.props.readonly) {
      return undefined;
    }

    if (typeof this.props.onClick !== 'undefined') {
      this.props.onClick(e);
      return undefined;
    }

    // make asynchronous call
    if (type === 'submit') {
      e.preventDefault();
      e.stopPropagation();

      this.setState({ buttonState: 'loading' });
      return this.handleSubmit();
    }
  };

  render() {
    const {
      icon,
      value,
      type = 'button',
      className = 'btn',
      loadingText = 'Sending...',
      successText = 'Done!',
      paddingless = false,
      transparent,
    } = this.props;

    let text = value;
    if (this.state.buttonState === 'loading') {
      text = loadingText;
    }

    if (this.state.buttonState === 'success') {
      text = successText;
    }

    return (
      <RootBtn
        ref={this.props.buttonRef}
        id={this.props.id}
        className={classNames(className, {
          active: this.props.isActive,
          warning: this.props.warning,
          error: this.state.buttonState === 'error',
          primary: this.props.primary,
          secondary: this.props.secondary,
          small: this.props.size === 'small',
          large: this.props.size === 'large',
          short: this.props.length === 'short',
          full: this.props.length === 'full',
          readonly: this.props.readonly,
          disabled: this.props.disabled,
          paddingless,
          transparent,
        })}
        type={type}
        disabled={this.props.disabled}
        onClick={this.handleClick}
      >
        {icon}
        {text}
      </RootBtn>
    );
  }
}

export default Button;
