export const publicRoutes = {
  COOKIES: '/cookies',
  LANDING: '/',
  LOGIN: '/login',
  OAUTH: '/oauth/:service',
  PRIVACY: '/privacy',
  RESETPASSWORD: '/reset-password',
  SCHEDULE: '/team/:team/member/:id',
  SIGNUP: '/register',
  TERMS: '/terms',
  TEAMPROJECTPAGE: '/team/:team/project/:projectID',
  TEAMGROUPPAGE: '/team/:team/group/:id',
};
