import React from 'react';
import { ToastStyle } from './style';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';

const ToastWrapper = (props) => {
  return (
    <ToastStyle>
      {props.title}
      <div className="icon" onClick={props.onClose}>
        <CloseIcon />
      </div>
    </ToastStyle>
  );
};

export default ToastWrapper;
