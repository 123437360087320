import React from 'react';
import classNames from 'classnames';
import { TooltipStyle } from './style';

const ToolTipWrapper = (props) => {
  return (
    <TooltipStyle {...props} className={classNames({ info: props.isInfo })}>
      {props.children}
    </TooltipStyle>
  );
};

export default ToolTipWrapper;
