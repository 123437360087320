import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import RouteContainer from './includes/RouteContainer';
import NoMatch from '../pages/404/NoMatch';
import Skeletons from '../components/Loading/Skeletons';
import PrivateRoute from './includes/PrivateRoute';
import AuthRoute from './includes/AuthRoute';
import ScrollToTop from '../components/ScrollToTopRouter/Index';
import MainDashboardContainer from '../components/Layouts/Main/Layout';
import Login from '../pages/Auth/Login/Login';
const Register = lazy(() => import('../pages/Auth/Register/Register'));
const CreateTeam = lazy(() => import('../pages/Auth/CreateTeam'));
const OAuth = lazy(() => import('../pages/Auth/OAuth/Service'));
const Forgot = lazy(() => import('../pages/Auth/Forgot'));
const Reset = lazy(() => import('../pages/Auth/Reset'));
const Invite = lazy(() => import('../pages/Auth/Invite'));
const Office = lazy(() => import('../pages/Office'));
const Today = lazy(() => import('../pages/Today/Today'));
const Stream = lazy(() => import('../pages/Stream/Stream'));
const Project = lazy(() => import('../pages/Team/Project/Id'));
const Group = lazy(() => import('../pages/Team/Group/Id'));
const Logout = lazy(() => import('../pages/Auth/Logout/Logout'));
const Settings = lazy(() => import('../pages/Settings'));

const Routes = ({ auth }) => {
  return (
    <Route
      render={({ location, history }) => (
        <MainDashboardContainer
          history={history}
          location={location}
          auth={auth}
        >
          <RouteContainer location={location}>
            <Suspense fallback={<Skeletons number={0} mt={'100px'} />}>
              <ScrollToTop>
                <Switch location={location}>
                  {/*Public Routes */}
                  <Route exact path="/" component={Login} />
                  <Route exact path="/oauth/:service" component={OAuth} />
                  <Route exact path="/invite" component={Invite} />
                  <Route exact path="/reset" component={Reset} />
                  <Route
                    exact
                    key="project"
                    path="/team/:team/project/:projectID"
                    location={location}
                    component={Project}
                  />
                  <Route
                    exact
                    key="group"
                    path="/team/:team/:type/:id"
                    location={location}
                    component={Group}
                  />
                  <Route
                    exact
                    key="member"
                    path="/team/:team/:type/:id"
                    location={location}
                    component={Group}
                  />
                  {/*AUTH ROUTES */}
                  <AuthRoute
                    exact
                    location={location}
                    key="login"
                    path="/login"
                  >
                    <Login history={history} />
                  </AuthRoute>
                  <AuthRoute
                    exact
                    location={location}
                    key="register"
                    path="/register"
                  >
                    <Register history={history} />
                  </AuthRoute>
                  <AuthRoute
                    exact
                    location={location}
                    key="forgot"
                    path="/forgot"
                  >
                    <Forgot history={history} />
                  </AuthRoute>
                  {/*Private Routes */}
                  <PrivateRoute
                    exact
                    key="create-team"
                    path="/create-team"
                    location={location}
                  >
                    <CreateTeam history={history} />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    key="today"
                    path="/today"
                    location={location}
                  >
                    <Today />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    key="office"
                    path="/office"
                    location={location}
                  >
                    <Office history={history} />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    key="stream"
                    path="/stream"
                    location={location}
                  >
                    <Stream />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    key="settings"
                    path="/settings"
                    location={location}
                  >
                    <Settings />
                  </PrivateRoute>
                  {/*Logout */}
                  <PrivateRoute
                    exact
                    key="logout"
                    path="/logout"
                    location={location}
                  >
                    <Logout />
                  </PrivateRoute>
                  {/*404 Route*/}
                  <Switch>
                    <Route path="/404" component={NoMatch} />
                    <Redirect from="*" to="/404" />
                  </Switch>
                </Switch>
              </ScrollToTop>
            </Suspense>
          </RouteContainer>
        </MainDashboardContainer>
      )}
    ></Route>
  );
};

export default Routes;
