const variables = {
  /*
   * Box shadow
   * ========================================================================== */
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.16)',
  /*
   * Colors
   * ========================================================================== */

  paletteBrandBase: '#0d64e8',
  paletteBrandDark: '#227be4',
  paletteteGrayDark: '#EEEEEE',
  paletteGreenBase: '#2cbc63',
  paletteDarkBase: '#000000',
  paletteBlueBase: '#295be6',
  paletteRedBase: '#000',
  paletteRedDarken20: ' #b8162f',
  paletteYellowBase: ' #ffe600',
  paletteWhiteBase: ' #fff',
  paletteBlackBase: ' #000000',
  palettePurpleBase: ' #774ee0',
  paletteGrayBase: ' #9aa1aa',
  paletteGrayBase2: ' #999999',
  paletteGrayBase2Dark: ' #555',
  paletteGrayDark3: '#595959',

  paletteDarkBorder: '#000000',
  paletteGrayBorder: '#eee',
  paletteGrayLight: '#F8F8F8',
  paletteGrayXLight: '#cccccc',
  paletteGrayText: '#666666',
  paletteGrayTextDark: '#666666',
  paletteGrayTextXXLight: '#c3c7cc',
  paletteGrayTextXLight: '#9aa1aa',

  /*
   * Colors (individual values)
   * ========================================================================== */

  paletteValGreenBase: '44, 188, 99',
  paletteValBlueBase: '41, 91, 230',
  paletteValRedBase: '235, 73, 98',
  paletteValRedDarken20: '184, 22, 47',
  paletteValYellowBase: '255, 230, 0',
  paletteValWhiteBase: '255, 255, 255',
  paletteValBlackBase: '0, 0, 0',
  paletteValPurpleBase: '119, 78, 224',
  paletteValGrayBase: '154, 161, 170',

  paletteValGrayBackground: '235, 237, 240',
  paletteValGrayLight: '232, 232, 232',
  paletteValGrayXXXLight: '245, 246, 247',
  paletteValGrayXXLight: '243, 243, 243',
  paletteValGrayXLight: '246, 246, 246',
  paletteValGrayText: '164, 170, 178',
  paletteValGrayTextDark: '98, 103, 109',
  paletteValGrayTextXXLight: '195, 199, 204',
  paletteValGrayTextXLight: '154, 161, 170',
  paletteValGrayTextXDark: '117, 122, 128',
  paletteValGrayTextXXDark: '61, 64, 69',
  paletteValGrayStroke: '230, 229, 230',
  paletteValGrayStrokeDark: '205, 205, 205',

  /*
   * Typography
   * ========================================================================== */

  fontRegular: `"Inter-UI-Regular", Arial, Roboto, sans-serif`,
  fontBold: `"Inter-UI-Bold", Arial, Roboto, sans-serif`,
  fontMedium: `"Inter-UI-Medium", Arial, Roboto, sans-serif`,

  typeSizeBase: '15px',

  /*
   * Layout
   * ========================================================================== */

  gridY: '118px',
  gridMax: '940px',
  groupBlockWidth: '308px',
  groupBlockHeight: '98px',
  groupBlockPadding: '8px',
  projectBlockWidth: '229px',
  projectBlockHeight: '110px',
  projectBlockPadding: '8px',

  /*
   * Inputs
   * ========================================================================== */

  baseInputPaddingX: '8px',
  baseInputPaddingY: '10px',
  baseInputPadding: '10px 8px',

  baseInputMarginX: '0',
  baseInputMarginY: '0',
  baseInputMargin: '0 0',

  baseInputMarginBleedX: '-8px',
  baseInputMarginBleedY: '0',
  baseInputMarginBleed: '0 -8px',

  baseInputBorder: '1px solid #EEEEEE',
  baseInputBorderRadius: '6px',
  baseInputWidth: '100%',
};

export default variables;
