import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { MainContext } from '../../Stores/MainStore';
import { useQuery } from '../../Globals/UseQuery';
import { readCookie } from '../../core/cookies';

// A wrapper for <Route> that redirects to the dashboard
// screen if you're authenticated.
export default function AuthRoute({ children, ...rest }) {
  const { state } = useContext(MainContext);
  const query = useQuery();
  const auth = readCookie('at') ? { token: readCookie('at') } : state.user;

  function redirectAuth() {
    if (query.has('next')) {
      return auth.next;
    } else {
      return `/office`;
    }
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth?.token ? (
          children
        ) : (
          <Redirect
            exact
            to={{
              pathname: redirectAuth(),
              state: { from: location },
              search: !query.has('next') ? query.toString() : false,
            }}
          />
        )
      }
    />
  );
}
