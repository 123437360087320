import React, { Component } from 'react';
import classNames from 'classnames';
import { TextBoxRoot } from './style';

class TextBox extends Component {
  constructor(props) {
    super(props);
    this.state = { isFocused: false };
  }

  componentDidUpdate(prevProps) {
    if (this.props.val !== prevProps.val) {
      this.updateStates({ value: this.props.val });
    }
  }

  updateStates = (states) => {
    this.setState(states);
  };

  handleBlur = (e) => {
    this.setState({ isFocused: false });
    this.props.onBlur && this.props.onBlur(e);
  };

  handleFocus = (e) => {
    this.setState({ isFocused: true });
    this.props.onFocus && this.props.onFocus(e);
  };

  handleChange = (e) => {
    const props = this.props;
    const target = e.currentTarget;
    if (props.updateStateOnChange) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ value: target.value || '' });
      props.onChange && props.onChange(target.value || '');
    }
  };

  render() {
    const { reactKey = 'input', noUnderline = true } = this.props;
    const props = this.props;
    const state = this.state;
    const styles = {};
    const classes = {
      inline: props.inline,
      readonly: props.readOnly,
      bleed: props.bleed,
      focused: state.isFocused,
      grouped: props.isGroup,
      error: props.isError,
      'no-underline': noUnderline,
    };
    let value = '';

    props.className && (classes[props.className] = true);
    props.color && (styles.color = props.color);
    value =
      props.updateStateOnChange && typeof state.value !== 'undefined'
        ? state.value
        : typeof props.val !== 'undefined'
        ? props.val
        : '';
    return (
      <TextBoxRoot className={classNames(classes)}>
        <div>
          {props.label && (
            <label className="label" htmlFor={props.name}>
              {props.label}
            </label>
          )}
          <input
            id={props.id}
            className={classNames(props.inputClassName, {
              first: props.isFirst,
              last: props.isLast,
              borderless: props.border === false,
            })}
            style={styles}
            ref={props.innerRef}
            key={reactKey}
            name={props.name}
            type={props.type || 'text'}
            value={value}
            min={props.min}
            max={props.max}
            tabIndex={props.tabIndex}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            readOnly={props.readOnly}
            disabled={props.disabled}
            autoFocus={props.autofocus}
            autoCorrect="off"
            autoComplete={props.autoComplete || 'off'}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onKeyDown={props.onKeyDown}
            onKeyUp={props.onKeyUp}
            onKeyPress={props.onKeyPress}
            onClick={props.onClick}
            onChange={this.handleChange}
          />
        </div>
      </TextBoxRoot>
    );
  }
}

export default TextBox;
