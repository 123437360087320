import React, { Component } from 'react';
import { Portal } from '@chakra-ui/react';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
import Avatar from '../Avatar';
import TextBox from '../TextBox';
import { ScaleFadeStyle } from './style';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { Query } from '../../core/Utils';
import { api, handleClientError } from '../../core/auth';

const ROLES = { admin: 'Owner', normal: 'Member', viewer: 'Limited' };
const PAGES = ['today', 'office', 'stream', 'settings'];
const GRID_WIDTH = 4;
class NavigationPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      term: '',
      suggestions: {
        pages: PAGES,
        memberships: [],
        projects: [],
        groups: [],
        pins: {},
        user: {},
        total: 0,
      },
      searchResults: [],
      selectedSuggestion: -1,
      isSubmitting: false,
      isAnimating: false,
      isVisible: false,
    };

    this._input = null;
    this._suggestions = null;
    this._pages = null;
    this._searchResults = null;
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleWindowKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isActive !== prevProps.isActive) {
      if (this.props.isActive) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleWindowKeyDown);
  }

  handleClick = () => {
    this.hide();
  };

  handleSearchKeyUp = (e) => {
    const term = e.target.value;
    if (term === this.state.term) {
      return;
    }

    this.setState({ term }, () => this.search());
  };

  handlePageClick = (e, pageName) => {
    const url = `/${pageName}`;
    e.preventDefault();
    e.stopPropagation();

    this.hide();
    this.props.history.push(url);
  };

  handleSuggestionClick = (e, type, entity) => {
    const _this = this;
    const url = `/team/${this.props.team}/${type}/${entity._id}`;
    e.preventDefault();
    e.stopPropagation();

    this.hide();
    if (window.location.pathname.substr(-24) !== entity._id) {
      this.setState({ term: '' }, () => {
        setTimeout(() => {
          this.props.history.push(url);
          _this.setState({
            suggestions: {
              pages: PAGES,
              memberships: [],
              projects: [],
              groups: [],
              pins: {},
              user: {},
              total: 0,
            },
          });
        }, 300);
      });
    }
  };

  handleWindowKeyDown = (e) => {
    const hasTerm = this.state.term && this.state.term.length;
    let gridWidth = hasTerm ? 1 : GRID_WIDTH;
    const searchResults = this._searchResults?.children || [];
    const suggestions = this._suggestions?.children || [];
    const pages = this._pages?.children || [];

    const elements = hasTerm ? searchResults : [...pages, ...suggestions];

    const key = e.keyCode || e.which || e.charCode;
    if (!this.props.isActive) {
      return;
    }

    // ESC
    if (key === 27) {
      this.hide();
      // ENTER
    } else if (key === 13) {
      const i = this.state.selectedSuggestion;
      const node = elements.length ? elements[i] : null;
      if (node) {
        node.click();
      }
      // TAB/LEFT/UP/RIGHT/DOWN
    } else if (
      e.shiftKey ||
      key === 9 ||
      key === 37 ||
      key === 38 ||
      key === 39 ||
      key === 40
    ) {
      const i = this.state.selectedSuggestion;
      const prevNode = elements.length ? elements[i] : null;
      let index;

      if (e.shiftKey && key === 9) {
        // SHIFT + TAB when it's focused to the first element, we need to give focus back to browser
        // in order to enable search input focus
        if (i === 0) {
          index = -1;
          // We need to set state here because it doesn't set if the index is -1, a.k.a no nextNode
          this.setState({ selectedSuggestion: index });
        } else {
          // SHIFT + TAB behaves like left arrow
          e.preventDefault();
          index = Math.max(i - 1, 0);
        }
        // TAB behaves like right arrow
      } else if (key === 9) {
        e.preventDefault();
        index = Math.min(i + 1, elements.length - 1);
      } else {
        switch (key) {
          // LEFT
          case 37:
            if (i === 0) {
              index = -1;
              // We need to set state here because it doesn't set if the index is -1, a.k.a no nextNode
              this.setState({ selectedSuggestion: index });
              this._input && this._input.focus();
            } else {
              if (i !== -1) index = Math.max(i - 1, 0);
            }
            break;
          // UP
          case 38:
            if (i < gridWidth) {
              index = -1;
              // We need to set state here because it doesn't set if the index is -1, a.k.a no nextNode
              this.setState({ selectedSuggestion: index });
              this._input && this._input.focus();
            } else {
              index = Math.min(i - gridWidth, i);
            }
            break;
          // RIGHT
          case 39:
            if (i !== -1) index = Math.min(i + 1, elements.length - 1);
            break;
          // DOWN
          case 40:
            // Focus to the first element when the focus is on the search bar
            // and down arrow is pressed for the first time
            const isFirstPress = i === -1;
            isFirstPress && e.preventDefault();
            index = Math.min(
              isFirstPress ? i + 1 : i + gridWidth,
              elements.length - 1
            );
            break;
          default:
            index = -1;
            break;
        }
      }

      const nextNode = elements.length ? elements[index] : null;
      if (nextNode) {
        if (prevNode) {
          prevNode.blur();
        }

        nextNode.focus();
        this.setState({ selectedSuggestion: index });
      }
    }
  };

  show = () => {
    this.search();
    this.setState({ isAnimating: true });
    this._input && this._input.focus();
    setTimeout(
      () => this.setState({ isAnimating: false, isVisible: true }),
      200
    );
  };

  hide = () => {
    const i = this.state.selectedSuggestion;
    const node = this._suggestions ? this._suggestions.children[i] : null;
    node && node.classList.remove('selected');
    if (!this.state.isVisible) {
      return;
    }

    this.setState({ isAnimating: true });
    setTimeout(() => {
      this.setState({
        selectedSuggestion: -1,
        isAnimating: false,
        isVisible: false,
      });
      this._input && this._input.blur();
      this.props.onClose && this.props.onClose();
    }, 50);
  };

  search = () => {
    const _this = this;
    const params = { teamName: this.props.team, term: this.state.term };
    if (!this.props.team || this.state.isSubmitting) {
      return;
    }

    this.setState({ isSubmitting: true });
    api(
      `v1/search?${Query(params)}`,
      'GET',
      null,
      (data) => {
        const pg = PAGES.filter((p) =>
          p.includes(_this.state.term.toLowerCase())
        );
        const p = data.projects || [];
        const g = data.groups || [];
        const m = data.memberships.filter((m) => m.role !== 'viewer') || [];
        const u = data.user || {};
        const pins = data.pins || {};
        const pp = pins.projects || [];
        const gp = pins.groups || [];

        _this.setState({
          isSubmitting: false,
          suggestions: {
            pages: pg,
            memberships: m,
            projects: p,
            groups: g,
            user: u,
            pins,
            total:
              m.length +
              p.length +
              g.length +
              pp.length +
              gp.length +
              pg.length,
          },
        });
      },
      (err) => {
        handleClientError(err)
          .then(() => {
            _this.search();
          })
          .catch((ex) => {
            throw new Error(ex);
          });

        _this.setState({ isSubmitting: false });
      }
    );
  };

  render() {
    const state = this.state;
    const suggestions = state.suggestions || {};
    const hasTerm = state.term && state.term.length;

    return (
      <Portal>
        <ScaleFadeStyle
          initialScale={0.9}
          in={state.isVisible}
          className={classNames('root', {
            init: !hasTerm && !suggestions.total,
            visible: state.isVisible,
          })}
        >
          <div className="mask" onClick={this.handleClick} />
          <div className="panel">
            <div className={classNames('input-search bar')}>
              <div className="icon">
                <SearchIcon />
              </div>
              <TextBox
                innerRef={(n) => (this._input = n)}
                placeholder="Search"
                val={state.term}
                border={false}
                noUnderline
                autoComplete="off"
                onKeyUp={this.handleSearchKeyUp}
                updateStateOnChange
              />
            </div>
            <div className="results" tabIndex={-1}>
              {hasTerm && suggestions.total > 0 && (
                <div
                  className="search-results"
                  ref={(n) => (this._searchResults = n)}
                >
                  <>
                    {suggestions.pages.length > 0 &&
                      suggestions.pages.map((page) => (
                        <button
                          key={page}
                          className={'search-item'}
                          onClick={(e) => this.handlePageClick(e, page)}
                        >
                          <div className="content" style={{ color: '#fff' }}>
                            <span style={{ textTransform: 'capitalize' }}>
                              {page}
                            </span>
                            <span>Open ↵</span>
                          </div>
                        </button>
                      ))}
                    {suggestions.memberships.length > 0 &&
                      suggestions.memberships.map((membership) => (
                        <button
                          key={membership}
                          className={'search-item'}
                          onClick={(e) =>
                            this.handleSuggestionClick(e, 'member', membership)
                          }
                        >
                          <div className="content" style={{ color: '#fff' }}>
                            <span style={{ textTransform: 'capitalize' }}>
                              {membership.name}
                            </span>
                            <span>Open ↵</span>
                          </div>
                        </button>
                      ))}
                    {suggestions.projects.length > 0 &&
                      suggestions.projects.map((p) => (
                        <button
                          key={p._id}
                          className={'search-item'}
                          onClick={(e) =>
                            this.handleSuggestionClick(e, 'project', p)
                          }
                        >
                          <div className="content" style={{ color: '#fff' }}>
                            <span>{p.name}</span>
                            <span>Open ↵</span>
                          </div>
                        </button>
                      ))}
                  </>
                </div>
              )}
              {!hasTerm && suggestions.total > 0 && (
                <>
                  {suggestions.pages.length > 0 && (
                    <div
                      className="page-results"
                      ref={(n) => (this._pages = n)}
                    >
                      {suggestions.pages.map((page) => {
                        return (
                          <button
                            key={page}
                            className={'page'}
                            onClick={(e) => this.handlePageClick(e, page)}
                          >
                            <div className="content" style={{ color: '#fff' }}>
                              <span style={{ textTransform: 'capitalize' }}>
                                {page}
                              </span>
                              <span>↵</span>
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  )}
                  {suggestions.memberships.length > 0 && (
                    <>
                      <div className="section-title">Team members</div>

                      <div className="user-results">
                        {suggestions.memberships.map((u) => (
                          <Tooltip
                            key={u._id}
                            label={
                              <div>
                                <div className="content">{u.name}</div>
                                <div className="meta">{ROLES[u.role]}</div>
                              </div>
                            }
                          >
                            <div
                              className="avatar"
                              onClick={(e) =>
                                this.handleSuggestionClick(e, 'member', u)
                              }
                            >
                              <Avatar imagePath={u.avatarUrl} />
                            </div>
                          </Tooltip>
                        ))}
                      </div>
                    </>
                  )}
                  {suggestions.projects.length > 0 && (
                    <>
                      <div className="section-title">Projects</div>
                      <div
                        className="project-results"
                        ref={(n) => (this._suggestions = n)}
                      >
                        {(suggestions.projects || []).map((p) => (
                          <button
                            key={p._id}
                            className={'project'}
                            onClick={(e) =>
                              this.handleSuggestionClick(e, 'project', p)
                            }
                          >
                            <div className="content" style={{ color: '#fff' }}>
                              <span>{p.name}</span>
                              <span>↵</span>
                            </div>
                          </button>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
              {hasTerm && suggestions.total === 0 && (
                <div className="empty">No results found</div>
              )}
            </div>
          </div>
        </ScaleFadeStyle>
      </Portal>
    );
  }
}

export default NavigationPanel;
