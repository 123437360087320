import React, { Suspense, useEffect, useContext, useCallback } from 'react';
import * as FS from '@fullstory/browser';
import { Box, Flex } from '@chakra-ui/react';
import GlobalStyle from './style';
import { MainContext } from '../../../Stores/MainStore';
import { publicRoutes } from '../../../Routes/includes/routesList';
import NavigationPanel from '../../NavigationPanel';
import { readCookie } from '../../../core/cookies/cookies';
import { matchPath } from 'react-router-dom';
import { IsTextNode } from '../../../core/Utils';
import { fullStoryOrg } from '../../../config';
import Header from '../../Header';
import { ReactComponent as SearchIcon } from '../../../icons/search.svg';
const SideMenu = React.lazy(() => import('../../SideMenu/SideMenu'));

const Layout = ({ history, location, auth, children }) => {
  const { state, dispatch } = useContext(MainContext);
  const authCheck = readCookie('at') ? { token: readCookie('at') } : auth;
  const team = readCookie('t') ?? readCookie('t');

  const routeIsPublic = Object.values(publicRoutes).find(
    (r) => r === location.pathname
  );

  const teamProjectPage = matchPath(location.pathname, {
    path: publicRoutes.TEAMPROJECTPAGE,
  });

  const teamGroupPage = matchPath(location.pathname, {
    path: publicRoutes.TEAMGROUPPAGE,
  });

  const schedulePage = matchPath(location.pathname, {
    path: publicRoutes.SCHEDULE,
  });

  const isExact =
    (teamProjectPage && teamProjectPage.isExact) ||
    (teamGroupPage && teamGroupPage.isExact) ||
    (schedulePage && schedulePage.isExact);

  const handleNavigation = useCallback(
    (e) => {
      dispatch({ type: 'navigation', payload: e.detail });
    },
    [dispatch]
  );

  const handleNavigationClose = () => {
    const navigation = state.navigation;
    navigation.isActive = false;
    dispatch({ type: 'navigation', payload: navigation });
  };

  const handleWindowKeyDown = useCallback(
    (e) => {
      const key = e.keyCode || e.which || e.charCode;
      const { isShowShortcuts } = state;
      if (!readCookie('u') || IsTextNode(e.target)) {
        return;
      }

      if (key === 74) {
        const navigation = state.navigation;
        e.preventDefault();
        e.stopPropagation();
        dispatch({
          type: 'navigation',
          payload: { isActive: !navigation.isActive },
        });
      } else if (key === 75) {
        dispatch({ type: 'isShowShortcuts', payload: !isShowShortcuts });
      } else {
        switch (key) {
          case 84:
            return history.push('/today');
          case 79:
            return history.push('/office');
          case 83:
            return history.push(`/team/${team}/member/${state.user._id}`);
          default:
            return;
        }
      }
    },
    [dispatch, state, history, team]
  );

  useEffect(() => {
    window.addEventListener('navigation', handleNavigation, false);
    window.addEventListener('keydown', handleWindowKeyDown);

    return () => {
      window.removeEventListener('navigation', handleNavigation, false);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, [handleNavigation, handleWindowKeyDown]);

  useEffect(() => {
    FS.init({ orgId: fullStoryOrg });
  }, []);

  const currentPage = location.pathname.split('/')[1];

  return (
    <>
      <GlobalStyle />
      <Box w="100vw" height="100vh">
        {authCheck?.token && (
          <div
            style={{
              position: 'fixed',
              bottom: '16px',
              left: '50%',
              zIndex: 99999,
              transform: 'translateX(-50%)',
            }}
          >
            <button
              style={{
                color: 'white',
                backgroundColor: 'black',
                padding: '8px',
                borderRadius: '50%',
              }}
              onClick={() => {
                const navigation = state.navigation;
                dispatch({
                  type: 'navigation',
                  payload: { isActive: !navigation.isActive },
                });
              }}
            >
              <SearchIcon />
            </button>
          </div>
        )}

        {authCheck?.token && team && !routeIsPublic && !isExact ? (
          <div className="gridPlatform">
            <div className="main-head">
              <Header currentPage={currentPage} />
            </div>
            <Suspense fallback={<div />}>
              <Flex flexGrow={1} display={'none'}>
                <SideMenu />
              </Flex>
            </Suspense>
            <div
              className={`dashboardContentContainer ${
                state.menuSlided ? 'contentSlided' : ''
              }`}
            >
              {children}
            </div>
          </div>
        ) : (
          children
        )}
        {team && (
          <NavigationPanel
            team={team}
            isActive={state.navigation.isActive}
            onClose={handleNavigationClose}
            history={history}
          />
        )}
      </Box>
    </>
  );
};

export default Layout;
