import React, { useContext, useEffect, useRef } from 'react';
import { MainContext } from './Stores/MainStore';
import Routes from './Routes/Routes';

function App() {
  const { state, dispatch } = useContext(MainContext);

  const userStateRef = useRef('');
  userStateRef.current = () => saveUserState();

  useEffect(() => {
    userStateRef.current();
  }, []);

  function saveUserState() {
    if (localStorage['appState']) {
      dispatch({
        type: 'user',
        payload: JSON.parse(localStorage['appState']).user,
      });
    }
  }

  return <Routes auth={state.user} />;
}

export default App;
