import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { MainContext } from '../../Stores/MainStore';
import { useQuery } from '../../Globals/UseQuery';
import { readCookie } from '../../core/cookies';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function PrivateRoute({ children, ...rest }) {
  const query = useQuery();
  const { state } = useContext(MainContext);
  const auth = readCookie('at') ? { token: readCookie('at') } : state.user;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth?.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/login`,
              state: { from: location },
              search: state.user?.logout
                ? false
                : `?next=${location.pathname}${
                    query.toString() ? '?' + query.toString() : ''
                  }`,
            }}
          />
        )
      }
    />
  );
}
