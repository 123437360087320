import React, { Component } from 'react';
import { toast } from '../../../components/Toast';
import Link from '../../../components/Link';
import Logo from '../../../components/Logo';
import TextBox from '../../../components/TextBox';
import Button from '../../../components/Button';
import { readCookie, eraseCookie } from '../../../core/cookies';
import { apiEndpoint } from '../../../config';
import { Root } from './style';
import classNames from 'classnames';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isVisible: false,
      isEmailError: false,
      isPasswordError: false,
    };
  }

  componentDidMount() {
    const q = this.props.params ? this.props.params.query : {};
    const user = readCookie('u');
    const team = readCookie('t');
    if (user && team) {
      if (team === 'undefined') {
        eraseCookie('at');
        eraseCookie('rt');
        eraseCookie('t');
        this.props.history.push('/login');
        return;
      }

      this.props.history.push('/office');
      return;
    }

    q.email && q.email.length && this.setState({ email: q.email });
    this.setState({ isVisible: true });
    document.title = 'Welcome back';
  }

  handleRegisterClick = () => {
    this.props.history.push('/register');
    return false;
  };

  handleForgotClick = () => {
    this.props.history.push('/forgot');
    return false;
  };

  handleEmailChange = (val) => {
    this.setState({ email: val, isEmailError: false });
  };

  handlePasswordChange = (val) => {
    this.setState({ password: val });
  };

  success = (json) => {
    const p = this.props.params;
    const t = readCookie('t');
    const data = json ? json.data || {} : {};
    const memberships = data.memberships || [];
    const membership = memberships.length ? memberships[0] : null;
    const team = membership ? membership.team.name || t : t;
    const next = p && p.query ? p.query.next || '' : false;
    const exp = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toGMTString();

    if (!data.token) {
      eraseCookie('at');
      eraseCookie('rt');
      eraseCookie('t');
      this.props.history.push('/login');
      return;
    }

    document.cookie = `at=${data.token.accessToken};path=/;expires=${exp}`;
    document.cookie = `rt=${data.token.refreshToken};path=/;expires=${exp}`;
    document.cookie = `u=${data.user._id};path=/;expires=${exp}`;
    if (!team || !team.length) {
      this.props.history.push('/create-team');
      return;
    }

    document.cookie = `t=${team};path=/;expires=${exp}`;
    let path = next;
    if (!next || next.length === 0) {
      path = '/office';
    }

    this.props.history.push(path);
  };

  error = (e) => {
    if (!e || !e.message) {
      return;
    }

    if (e.message.indexOf('Invalid format') === 0) {
      this.setState({ isEmailError: true });
    } else {
      this.setState({ isEmailError: true, isPasswordError: true });
    }

    toast(e.message);
  };

  render() {
    const endpoint = `${apiEndpoint}/v1/auth/login`;
    if (!this.state.isVisible) {
      return false;
    }

    return (
      <Root>
        <div className="header">
          <div className="left">
            <Logo />
          </div>
          <div className="right">
            <Button
              length="short"
              value="Sign up"
              onClick={this.handleRegisterClick}
            />
          </div>
        </div>

        <div className="content">
          <div className="container">
            <form action={endpoint} method="POST" id="login">
              <div className={classNames({ section: true, inputs: true })}>
                <TextBox
                  placeholder="Email address"
                  name="email"
                  val={this.state.email}
                  isGroup
                  isFirst
                  isError={this.state.isEmailError}
                  onChange={this.handleEmailChange}
                  updateStateOnChange
                  noUnderline
                  autofocus
                />

                <div className="password">
                  <TextBox
                    placeholder="Password"
                    type="password"
                    name="password"
                    val={this.state.password}
                    isGroup
                    isLast
                    isError={this.state.isPasswordError}
                    onChange={this.handlePasswordChange}
                    updateStateOnChange
                    noUnderline
                  />
                  <Link to="/forgot" onClick={this.handleForgotClick}>
                    Forgot?
                  </Link>
                </div>
              </div>

              <div className="actions">
                <div className="button">
                  <Button
                    value="Login"
                    type="submit"
                    length="short"
                    method="POST"
                    action={endpoint}
                    success={this.success}
                    error={this.error}
                    form="login"
                    primary
                    loadingText="Logging in..."
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Root>
    );
  }
}

export default Login;
