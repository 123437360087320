import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Box } from '@chakra-ui/react';

const Skeletons = (props) => (
  <Box
    position="absolute"
    left="0"
    right="0"
    bg="transparent"
    w="100%"
    p={0}
    color="white"
    margin={`${props.mt ? props.mt : '0'} auto`}
  >
    {new Array(props.number).fill(null, 1).map(() => (
      <Skeleton
        colorScheme="blackAlpha"
        height="20px"
        my="20px"
        key={Math.random().toString(36).substr(2, 9)}
      />
    ))}
  </Box>
);

Skeletons.defaultProps = {
  number: 10,
};

Skeletons.propTypes = {
  number: PropTypes.number,
};

export default Skeletons;
