import styled from 'styled-components';
import variables from '../variables';

export const TextBoxRoot = styled.div`
  transition: border 0.2s;

  &:not(.no-underline) {
    border-bottom: 1px solid #eee;
  }

  &.focused {
    border-bottom-color: transparent;

    input {
      z-index: 1;
    }
  }

  &.error {
    input {
      box-shadow: inset 0 0 0 1px ${variables.paletteRedBase} !important;
    }
  }

  &.grouped {
    position: relative;

    &:hover {
      input {
        z-index: 1;
      }
    }

    &.focused {
      input {
        box-shadow: inset 0 0 0 1px ${variables.paletteDarkBorder};
        z-index: 2;
      }
    }

    &.error {
      input {
        z-index: 3;
      }
    }

    input {
      box-shadow: inset 0 0 0 1px ${variables.paletteGrayBorder};
      border: 0;
      outline: 0;
      border-radius: 0;
      margin-top: -1px;
      position: relative;
      -webkit-appearance: none;

      &.first {
        border-top-left-radius: ${variables.baseInputBorderRadius};
        border-top-right-radius: ${variables.baseInputBorderRadius};
        margin-top: 0;
      }

      &.last {
        border-bottom-left-radius: ${variables.baseInputBorderRadius};
        border-bottom-right-radius: ${variables.baseInputBorderRadius};
      }
    }
  }

  & > div {
    margin: ${variables.baseInputMargin};
  }

  &.bleed > div {
    margin: ${variables.baseInputMarginBleed};
  }

  input {
    background-color: #ffffff;
    border-radius: ${variables.baseInputBorderRadius};
    border: ${variables.baseInputBorder};
    color: ${variables.paletteBlackBase};
    display: block;
    font-size: ${variables.typesizeBase};
    line-height: 20px;
    padding: ${variables.baseInputPadding};
    transition: background-color 0.2s, box-shadow 0.2s ease-in-out;
    width: ${variables.baseInputWidth};
    outline: 0;
    -webkit-appearance: none;

    &::placeholder {
      color: ${variables.paletteGrayBase2};
    }

    &:hover {
      background-color: ${variables.paletteGrayLight};
    }

    &:focus {
      box-shadow: inset 0 0 0 1px ${variables.paletteDarkBorder};

      &:hover {
        background-color: transparent;
      }
    }

    &.borderless {
      border: none;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .inline {
    display: inline-block;
  }

  .readonly {
    input,
    textarea {
      color: ${variables.paletteGrayBase2};
      cursor: default;

      &:focus {
        box-shadow: none;
      }
    }
  }
`;
