import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
const duration = 0.3;
const delay = 0.1;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.2,
      easeInOut: [0.43, 0.13, 0.23, 0.96],
      duration: duration,
      delay: delay,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      duration: duration,
      easeInOut: [0.43, 0.13, 0.23, 0.96],
    },
  },
};
const RouteContainer = ({ location, children }) => (
  <AnimatePresence key={location.pathname + 'animate'} exitBeforeEnter>
    <motion.main
      className="content"
      key={location.pathname}
      variants={variants}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      {children}
    </motion.main>
  </AnimatePresence>
);

export default RouteContainer;
