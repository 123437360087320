import { ScaleFade, createStandaloneToast } from '@chakra-ui/react';
import ToastWrapper from './Toast';

const toast = (message, options) => {
  const chakraToast = createStandaloneToast();
  const title = typeof message === 'string' ? message : message.toString();
  return chakraToast({
    position: 'bottom-right',
    render: (props) => (
      <ScaleFade initialScale={0.75} in>
        <ToastWrapper {...props} {...options} title={title} />
      </ScaleFade>
    ),
  });
};

export default toast;
