import history from './history';

export function EmptyFunction() {}

export function LocalStorageGet(key) {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  return localStorage.getItem(key);
}

export function LocalStorageSet(key, obj) {
  try {
    const item = localStorage.getItem(key);
    const pref = item ? JSON.parse(item) : {};

    localStorage.setItem(key, JSON.stringify(Object.assign({}, pref, obj)));
  } catch (e) {}
}

export function LocalStorageDel(key) {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  return localStorage.removeItem(key);
}

export function WindowHeight() {
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
}

export function WindowWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

export function GetTransformMatrix(n) {
  let matrix = [];
  if (window.getComputedStyle && n) {
    const s = getComputedStyle(n);
    const t =
      s.transform ||
      s.webkitTransform ||
      s.MozTransform ||
      s.msTransform ||
      s.OTransform;
    const m = t.match(/^matrix\((.+)\)$/);

    if (m.length > 0) {
      const vals = m[1].split(',');
      if (vals.length === 6) {
        matrix = vals;
      }
    }
  }

  return matrix;
}

export function SetTransformMatrix(n, rule) {
  n.style.webkitTransform = rule;
  n.style.MozTransform = rule;
  n.style.msTransform = rule;
  n.style.OTransform = rule;
  n.style.transform = rule;
}

export function Transform(rule) {
  return {
    WebkitTransform: rule,
    MozTransform: rule,
    msTransform: rule,
    transform: rule,
  };
}

export function BoxShadow(rule) {
  return {
    WebkitBoxShadow: rule,
    MozBoxShadow: rule,
    msBoxShadow: rule,
    boxShadow: rule,
  };
}

export function EaseInOut(currentTime, start, change, duration) {
  currentTime /= duration / 2;
  if (currentTime < 1) {
    return (change / 2) * currentTime * currentTime + start;
  }

  currentTime -= 1;
  return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
}

export function AnimateScroll(node, to, time) {
  const start = node.scrollLeft;
  const diff = to - start + 1;
  const tick = 10;
  const animateScroll = function (elapsedTime) {
    elapsedTime += tick;
    node.scrollLeft = EaseInOut(elapsedTime, start, diff, time);
    if (elapsedTime < time) {
      setTimeout(() => {
        animateScroll(elapsedTime);
      }, tick);
    }
  };

  animateScroll(0);
}

export function Query(params) {
  const q = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return q;
}

export function EscapeRegExp(s) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function Unique(array, key) {
  const map = {};
  return array.filter((item) => {
    if (map[item[key]]) {
      return false;
    }

    map[item[key]] = true;
    return true;
  });
}

export function ReadableFileSize(bytes, decimals) {
  const k = 1024;
  const dm = decimals || 0;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i;

  if (bytes === 0) {
    return '0 bytes';
  }

  i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function IsTextNode(n) {
  if (!n) {
    return false;
  }

  return (
    /textarea|select/i.test(n.nodeName) ||
    n.type === 'text' ||
    n.type === 'number' ||
    n.type === 'password' ||
    n.isContentEditable
  );
}

export function IsUndef(data) {
  return typeof data === 'undefined';
}

export function SetTitle(text, count) {
  const counter = count && count > 0 ? `(${count}) ` : '';
  document.title = `${counter}${text} | Roadmap`;
}

export function SendBrowserNotification(params) {
  const title = params.title || 'Roadmap';
  let n;

  if (!('Notification' in window) || !params.body || !params.body.length) {
    return;
  }

  if (window.Notification.permission === 'granted') {
    n = new window.Notification(title, params);
    params.url &&
      (n.onclick = () => {
        history.push(params.url);
      });
  } else if (window.Notification.permission !== 'denied') {
    window.Notification.requestPermission((state) => {
      if (state === 'granted') {
        n = new window.Notification(title, params);
        params.url &&
          (n.onclick = () => {
            history.push(params.url);
          });
      }
    });
  }
}
